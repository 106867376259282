import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  ScatterChart,
  Scatter,
} from "recharts";
import Select from "react-select";

const COLORS = [
  "#54A7A8",
  "#3F7D7E",
  "#2A5354",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8",
  "#82CA9D",
];

type DataItem = Record<string, string | number>;

interface GenericChartProps {
  data: DataItem[];
  theme: "light" | "dark";
}

const GenericChart: React.FC<GenericChartProps> = ({ theme, data }) => {
  const keys = useMemo(() => {
    if (data.length === 0) return [];
    return Object.keys(data[0]);
  }, [data]);

  const [chartType, setChartType] = useState<
    "bar" | "pie" | "combined" | "scatter"
  >("bar");
  const [barKeys, setBarKeys] = useState<string[]>([]);
  const [xAxisKey, setXAxisKey] = useState<string>(keys[0] || "");
  const [selectedKeys, setSelectedKeys] = useState<string[]>(() => {
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      return keys.length > 1 ? [keys[1]] : [];
    }
    return [];
  });

  const formatValue = (value: string | number): string => {
    if (typeof value === "number") {
      return value.toLocaleString();
    }
    return value;
  };

  const colorTheme = {
    light: {
      background: "#fff",
      color: "#000",
      borderColor: "#000",
      selectMultiValueBackground: "#e6e6e6",
      selectOptionHoverBackground: "#f2f2f2",
      removeMultiValueBackground: "#d9d9d9",
    },
    dark: {
      background: "#000",
      color: "#fff",
      borderColor: "#fff",
      selectMultiValueBackground: "#333",
      selectOptionHoverBackground: "#444",
      removeMultiValueBackground: "#555",
    },
  };
  // Options for the 'Type' selector
  const typeOptions = [
    { value: "bar", label: "Bar Chart" },
    { value: "pie", label: "Pie Chart" },
    { value: "combined", label: "Combined Bar and Line Chart" },
    { value: "scatter", label: "Scatter Plot" },
  ];

  // Options for the 'Dimension' selector
  const dimensionOptions = keys.map((key) => ({ value: key, label: key }));

  // Options for the 'Measure' selector
  const measureOptions = keys
    .filter((key) => key !== xAxisKey)
    .map((key) => ({ value: key, label: key }));

  // Options for the 'Select Bar Keys' selector
  const barKeyOptions = keys
    .filter((key) => key !== xAxisKey)
    .map((key) => ({ value: key, label: key }));

  const customStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
      color: colorTheme[theme].color,
      borderColor: colorTheme[theme].borderColor,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
      color: colorTheme[theme].color,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colorTheme[theme].selectMultiValueBackground
        : colorTheme[theme].background,
      color: colorTheme[theme].color,
      ":hover": {
        backgroundColor: colorTheme[theme].selectOptionHoverBackground,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].selectMultiValueBackground,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: colorTheme[theme].color,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: colorTheme[theme].color,
      ":hover": {
        backgroundColor: colorTheme[theme].removeMultiValueBackground,
        color: colorTheme[theme].color,
      },
    }),
  };

  const renderBarChart = () => (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip
          contentStyle={{
            backgroundColor: colorTheme[theme].background,
            borderColor: colorTheme[theme].borderColor,
            color: colorTheme[theme].color,
            opacity: 0.9,
          }}
          cursor={false}
          formatter={(value: string | number) => formatValue(value)}
        />
        <Legend />
        {selectedKeys
          .filter((key) => key !== xAxisKey)
          .map((key, index) => (
            <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]} />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );

  const renderPieChart = () => (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        {selectedKeys.map((key, index) => (
          <Pie
            key={key}
            data={data}
            dataKey={key}
            nameKey={xAxisKey}
            cx="50%"
            cy="50%"
            outerRadius={50 + index * 20}
            fill={COLORS[index % COLORS.length]}
            label
          >
            {data.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
        ))}
        <Tooltip formatter={(value: string | number) => formatValue(value)} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  const renderCombinedChart = () => (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data}>
        <XAxis dataKey={xAxisKey} />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip
          contentStyle={{
            backgroundColor: colorTheme[theme].background,
            borderColor: colorTheme[theme].borderColor,
            color: colorTheme[theme].color,
            opacity: 0.9,
          }}
          cursor={false}
          formatter={(value: string | number) => formatValue(value)}
        />
        <Legend />
        {/* Render multiple bars based on selected bar keys */}
        {barKeys.map((key, index) => (
          <Bar
            yAxisId="left"
            key={key}
            dataKey={key}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
        {/* Render multiple lines for non-bar keys */}
        {selectedKeys
          .filter((key) => key !== xAxisKey && !barKeys.includes(key))
          .map((key, index) => (
            <Line
              key={key}
              yAxisId="right"
              type="monotone"
              dataKey={key}
              stroke={COLORS[(index + barKeys.length) % COLORS.length]}
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );

  const renderScatterChart = () => {
    // Transform data for scatter plot
    const transformedData = data.map(item => ({
      ...item,
      [`${barKeys[0]}-${selectedKeys[0]}`]: {
        x: item[barKeys[0]],
        y: item[selectedKeys[0]]
      }
    }));
  
    return (
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <XAxis 
            type="number"
            dataKey={barKeys[0]}
            name={barKeys[0]}
            label={{ value: barKeys[0], position: 'bottom' }}
          />
          <YAxis 
            type="number"
            dataKey={selectedKeys[0]}
            name={selectedKeys[0]}
          />
          <Tooltip 
            contentStyle={{
              backgroundColor: colorTheme[theme].background,
              borderColor: colorTheme[theme].borderColor,
              color: colorTheme[theme].color,
              opacity: 0.9,
            }}
            cursor={{ strokeDasharray: '3 3' }}
            formatter={(value: string | number) => formatValue(value)}
          />
          <Legend />
          {selectedKeys
            .filter((key) => key !== xAxisKey && !barKeys.includes(key))
            .map((yKey, index) => (
              <Scatter
                key={yKey}
                name={yKey}
                data={data}
                fill={COLORS[index % COLORS.length]}
                dataKey={yKey}
              />
            ))}
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  const renderChart = () => {
    switch (chartType) {
      case "bar":
        return renderBarChart();
      case "pie":
        return renderPieChart();
      case "combined":
        return renderCombinedChart();
      case "scatter":
        return renderScatterChart();
      default:
        return null;
    }
  };

  return (
    <div>
      {/* 'Type' Selector on top */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Type:</span>
          <Select
            options={typeOptions}
            value={typeOptions.find((option) => option.value === chartType)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                setChartType(
                  selectedOption.value as "bar" | "pie" | "combined" | "scatter"
                );
              }
            }}
            styles={{
              ...customStyles,
              control: (provided) => ({
                ...provided,
                ...customStyles.control,
                width: "280px",
                background: colorTheme[theme].background,
              }),
            }}
          />
        </div>
      </div>

      {/* 'Dimension' and 'Measure' Selectors side by side */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "20px",
          marginTop: "10px",
        }}
      >
        {(chartType === "bar" ||
          chartType === "combined" ||
          chartType === "scatter" ||
          chartType === "pie") && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Dimension:</span>
            <Select
              options={dimensionOptions}
              value={dimensionOptions.find(
                (option) => option.value === xAxisKey
              )}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setXAxisKey(selectedOption.value);
                }
              }}
              styles={{
                ...customStyles,
                control: (provided, state) => ({
                  ...provided,
                  ...customStyles.control,
                  width: "280px",
                  marginTop: "10px",
                  background: colorTheme[theme].background,
                  borderColor: state.isFocused
                    ? colorTheme[theme].background
                    : colorTheme[theme].borderColor,
                }),
              }}
            />
          </div>
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          {(chartType === "bar" || chartType === "pie") && (
            <>
              <span>Measures:</span>
              <Select
                isMulti
                options={measureOptions}
                value={measureOptions.filter((option) =>
                  selectedKeys.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const values = selectedOptions.map((option) => option.value);
                  setSelectedKeys(values);
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={{
                  ...customStyles,
                  control: (provided) => ({
                    ...provided,
                    ...customStyles.control,
                    width: "400px",
                    background: colorTheme[theme].background,
                    marginTop: "10px",
                  }),
                }}
                placeholder="Select measures..."
              />
            </>
          )}

          {chartType === "scatter" && (
            <>
              <div>
                <span>Measures (X axis):</span>
                <Select
                  isMulti
                  options={barKeyOptions}
                  value={barKeyOptions.filter((option) =>
                    barKeys.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setBarKeys(values);
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={{
                    ...customStyles,
                    control: (provided) => ({
                      ...provided,
                      ...customStyles.control,
                      width: "400px",
                      marginTop: "10px",
                      background: colorTheme[theme].background,
                    }),
                  }}
                  placeholder="Select measures..."
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <span>Measures (Y axis):</span>
                <Select
                  isMulti
                  options={measureOptions}
                  value={measureOptions.filter((option) =>
                    selectedKeys.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedKeys(values);
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={{
                    ...customStyles,
                    control: (provided) => ({
                      ...provided,
                      ...customStyles.control,
                      width: "400px",
                      background: colorTheme[theme].background,
                      marginTop: "10px",
                    }),
                  }}
                  placeholder="Select measures..."
                />
              </div>
            </>
          )}

          {chartType === "combined" && (
            <>
              <div>
                <span>Measures (Left axis):</span>
                <Select
                  isMulti
                  options={barKeyOptions}
                  value={barKeyOptions.filter((option) =>
                    barKeys.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setBarKeys(values);
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={{
                    ...customStyles,
                    control: (provided) => ({
                      ...provided,
                      ...customStyles.control,
                      width: "400px",
                      marginTop: "10px",
                      background: colorTheme[theme].background,
                    }),
                  }}
                  placeholder="Select measures..."
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <span>Measures (Right axis):</span>
                <Select
                  isMulti
                  options={measureOptions}
                  value={measureOptions.filter((option) =>
                    selectedKeys.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedKeys(values);
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={{
                    ...customStyles,
                    control: (provided) => ({
                      ...provided,
                      ...customStyles.control,
                      width: "400px",
                      background: colorTheme[theme].background,
                      marginTop: "10px",
                    }),
                  }}
                  placeholder="Select 2nd measures..."
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* Render the Chart */}
      <div style={{ marginTop: "20px" }}>{renderChart()}</div>
    </div>
  );
};

export default GenericChart;
