import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import Select from "react-select";

const COLORS = [
  "#54A7A8",
  "#3F7D7E",
  "#2A5354",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8",
  "#82CA9D",
];

type DataItem = Record<string, string | number>;

interface GenericChartMobileProps {
  data: DataItem[];
  theme: "light" | "dark";
}

const GenericChartMobile: React.FC<GenericChartMobileProps> = ({
  theme,
  data,
}) => {
  const keys = useMemo(() => {
    if (data.length === 0) return [];
    return Object.keys(data[0]);
  }, [data]);

  const [chartType, setChartType] = useState<"bar" | "pie" | "combined">("bar");
  const [xAxisKey, setXAxisKey] = useState<string>(keys[0] || "");
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [barKeys, setBarKeys] = useState<string[]>([]);

  const formatValue = (value: string | number): string => {
    if (typeof value === "number") {
      return value.toLocaleString();
    }
    return value;
  };

  const colorTheme = {
    light: {
      background: "#fff",
      color: "#000",
      borderColor: "#000",
      selectMultiValueBackground: "#e6e6e6",
      selectOptionHoverBackground: "#f2f2f2",
      removeMultiValueBackground: "#d9d9d9",
    },
    dark: {
      background: "#000",
      color: "#fff",
      borderColor: "#fff",
      selectMultiValueBackground: "#333",
      selectOptionHoverBackground: "#444",
      removeMultiValueBackground: "#555",
    },
  };

  const typeOptions = [
    { value: "bar", label: "Bar Chart" },
    { value: "combined", label: "Combined Bar and Line Chart" },
  ];

  const dimensionOptions = keys.map((key) => ({ value: key, label: key }));

  const measureOptions = keys
    .filter((key) => key !== xAxisKey)
    .map((key) => ({ value: key, label: key }));

  const barKeyOptions = keys
    .filter((key) => key !== xAxisKey)
    .map((key) => ({ value: key, label: key }));

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
      color: colorTheme[theme].color,
      borderColor: colorTheme[theme].borderColor,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
      color: colorTheme[theme].color,
    }),
    option: (provided: any, state: { isSelected: any }) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colorTheme[theme].selectMultiValueBackground
        : colorTheme[theme].background,
      color: colorTheme[theme].color,
      ":hover": {
        backgroundColor: colorTheme[theme].selectOptionHoverBackground,
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].background,
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: colorTheme[theme].selectMultiValueBackground,
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: colorTheme[theme].color,
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: colorTheme[theme].color,
      ":hover": {
        backgroundColor: colorTheme[theme].removeMultiValueBackground,
        color: colorTheme[theme].color,
      },
    }),
  };

  const renderBarChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip
          contentStyle={{
            backgroundColor: colorTheme[theme].background,
            borderColor: colorTheme[theme].borderColor,
            color: colorTheme[theme].color,
            opacity: 0.9,
          }}
          cursor={false}
          formatter={(value: string | number) => formatValue(value)}
        />
        <Legend />
        {selectedKeys
          .filter((key) => key !== xAxisKey)
          .map((key, index) => (
            <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]} />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );

  const renderPieChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        {selectedKeys.map((key, index) => (
          <Pie
            key={key}
            data={data}
            dataKey={key}
            nameKey={xAxisKey}
            cx="50%"
            cy="50%"
            outerRadius={50 + index * 20}
            fill={COLORS[index % COLORS.length]}
            label
          >
            {data.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
            ))}
          </Pie>
        ))}
        <Tooltip formatter={(value: string | number) => formatValue(value)} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );

  const renderCombinedChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={data}>
        <XAxis dataKey={xAxisKey} />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip
          contentStyle={{
            backgroundColor: colorTheme[theme].background,
            borderColor: colorTheme[theme].borderColor,
            color: colorTheme[theme].color,
            opacity: 0.9,
          }}
          cursor={false}
          formatter={(value: string | number) => formatValue(value)}
        />
        <Legend />
        {barKeys.map((key, index) => (
          <Bar
            yAxisId="left"
            key={key}
            dataKey={key}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
        {selectedKeys
          .filter((key) => key !== xAxisKey && !barKeys.includes(key))
          .map((key, index) => (
            <Line
              key={key}
              yAxisId="right"
              type="monotone"
              dataKey={key}
              stroke={COLORS[(index + barKeys.length) % COLORS.length]}
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );

  const renderChart = () => {
    switch (chartType) {
      case "bar":
        return renderBarChart();
      case "pie":
        return renderPieChart();
      case "combined":
        return renderCombinedChart();
      default:
        return null;
    }
  };

  return (
    <div style={{ padding: "10px", fontSize: "14px" }}>
      {/* 'Type' Selector on top */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <span>Type:</span>
        <Select
          options={typeOptions}
          value={typeOptions.find((option) => option.value === chartType)}
          onChange={(selectedOption) => {
            if (selectedOption) {
              setChartType(selectedOption.value as "bar" | "pie" | "combined");
            }
          }}
          styles={customStyles}
        />
      </div>

      {/* 'Dimension' and 'Measure' Selectors */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {(chartType === "bar" || chartType === "combined") && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Dimension:</span>
            <Select
              options={dimensionOptions}
              value={dimensionOptions.find(
                (option) => option.value === xAxisKey
              )}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setXAxisKey(selectedOption.value);
                }
              }}
              styles={customStyles}
            />
          </div>
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          {chartType === "bar" && (
            <>
              <span>Measures (Left axis):</span>
              <Select
                isMulti
                options={measureOptions}
                value={measureOptions.filter((option) =>
                  selectedKeys.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const values = selectedOptions.map((option) => option.value);
                  setSelectedKeys(values);
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={customStyles}
                placeholder="Select measures..."
              />
            </>
          )}

          {chartType === "combined" && (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Measures (Left axis):</span>
                <Select
                  isMulti
                  options={barKeyOptions}
                  value={barKeyOptions.filter((option) =>
                    barKeys.includes(option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const values = selectedOptions.map(
                      (option) => option.value
                    );
                    setBarKeys(values);
                  }}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={customStyles}
                  placeholder="Select bar keys..."
                />
              </div>
              <span>Measures (Right axis):</span>
              <Select
                isMulti
                options={measureOptions}
                value={measureOptions.filter((option) =>
                  selectedKeys.includes(option.value)
                )}
                onChange={(selectedOptions) => {
                  const values = selectedOptions.map((option) => option.value);
                  setSelectedKeys(values);
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={customStyles}
                placeholder="Select measures..."
              />
            </>
          )}
        </div>
      </div>

      {/* Render the Chart */}
      <div style={{ marginTop: "20px" }}>{renderChart()}</div>
    </div>
  );
};

export default GenericChartMobile;
