import React from 'react';
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface RevenueData {
  Year: number;
  total_revenue: string;
  total_revenue_all_channels: string;
  revenue_share_percentage: string;
}

interface RevenueShareChartProps {
  data: RevenueData[];
}

const formatRevenue = (value: string) => {
  const num = parseInt(value, 10);
  return `$${(num / 1e9).toFixed(2)}B`;
};

const formatPercentage = (value: string) => `${parseFloat(value).toFixed(2)}%`;

const RevenueShareChart: React.FC<RevenueShareChartProps> = ({ data }) => {
  // Define colors
  const barColor1 = '#54A7A8';   // Primary bar color
  const barColor2 = '#3F7D7E';   // Secondary bar color
  const lineColor = '#2A5354';   // Line color for revenue share percentage
  const textColor = '#2A5354';   // Consistent text color for all elements

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
         
        <XAxis dataKey="Year" />
        <YAxis yAxisId="left" orientation="left" stroke={barColor1} />
        <YAxis yAxisId="right" orientation="right" stroke={lineColor} />
        <Tooltip
          contentStyle={{
            backgroundColor: "rgba(255,255,255,0.8)",
            borderColor: "#ccc",
          }}
          formatter={(value: string, name: string) => {
            if (name === "Total Revenue") return formatRevenue(value);
            if (name === "Total Revenue (All Channels)") return formatRevenue(value);
            if (name === "Revenue Share") return formatPercentage(value);
            return value;
          }}
        />
        <Legend />
        <Bar yAxisId="left" dataKey="total_revenue" fill={barColor1} name="Total Revenue">
          {data.map((entry, index) => (
            <text
              key={`total-revenue-${index}`}
              x={index * 300 + 150}
              y={180}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatRevenue(entry.total_revenue)}
            </text>
          ))}
        </Bar>
        <Bar yAxisId="left" dataKey="total_revenue_all_channels" fill={barColor2} name="Total Revenue (All Channels)">
          {data.map((entry, index) => (
            <text
              key={`total-revenue-all-${index}`}
              x={index * 300 + 150}
              y={60}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatRevenue(entry.total_revenue_all_channels)}
            </text>
          ))}
        </Bar>
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="revenue_share_percentage"
          stroke={lineColor}
          name="Revenue Share"
        >
          {data.map((entry, index) => (
            <text
              key={`revenue-share-${index}`}
              x={index * 300 + 150}
              y={350}
              fill={textColor}
              textAnchor="middle"
              dy={-10}
            >
              {formatPercentage(entry.revenue_share_percentage)}
            </text>
          ))}
        </Line>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueShareChart;
