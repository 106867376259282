import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

type SupplierData = {
  "Supplier Name": string;
  total_invoice_value: number;
};

type SupplierInvoiceChartProps = {
  data: SupplierData[];
};

const SupplierInvoiceChart: React.FC<SupplierInvoiceChartProps> = ({
  data,
}) => {
  const colors = ["#54A7A8", "#3F7D7E", "#2A5354", "#E7E6E6"];

  const formatYAxis = (value: number) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    return `$${(value / 1000).toFixed(0)}K`;
  };

  const formatTooltip = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 160,
        }}
      >
         
        <XAxis
          dataKey="Supplier Name"
          angle={-45}
          textAnchor="end"
          interval={0}
          height={150}
        />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip formatter={formatTooltip} />
        <Legend />
        {/* <Bar dataKey="total_invoice_value" fill="#54A7A8" name="Total Invoice Value" /> */}
        <Bar dataKey="total_invoice_value" name="Total Invoice Value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SupplierInvoiceChart;
