import React, { useEffect, useState } from "react";
import RevenueChart from "./charts/RevenueChart";
import PromoChart from "./charts/PromoChart";
import DiscountDepthChart from "./charts/DiscountDepthChart";
import ProductPriceChart from "./charts/ProductPriceChart";
import SizePriceChart from "./charts/SizePriceChart";
import BrandPriceChart from "./charts/BrandPriceChart";
import RevenueChartDual from "./charts/RevenueChartDual";
import RevenueGrowth from "./charts/RevenuGrowth";
import GrowthChart from "./charts/GrowthChart";
import RevenueShareChart from "./charts/RevenueShareChart";
import RevenueGrowthChart from "./charts/RevenueGrowthChart";
import WaterfallChart from "./charts/WaterfallChart";
import ProductMetricsChart from "./charts/ProductMetricsChart";
import PromoMetricsChart from "./charts/PromoMetricsChart";
import PromoVolumeChart from "./charts/PromoVolumeChart";
import SupplierInvoiceChart from "./charts/SupplierInvoiceChart";
import TerminalTypeChart from "./charts/TerminalTypeChart";
import ProductPriceInfo from "./charts/ProductPriceInfo";
import BrandCAGRChart from "./charts/BrandCAGRChart";
import GrowthContributionChart from "./charts/GrowthContributionChart";
import GrowthComponentsChart from "./charts/GrowthComponentsChart";
import SKUPerformanceDashboard from "./charts/SKUPerformanceDashboard";
import GenericChart from "./charts/GenericChart";
import GenericChartMobile from "./charts/GenericChartMobile";

interface HCChartProps {
  isLoading: boolean;
  error: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: any[];
  chartID: number;
  theme: 'light' | 'dark';
}

const HCChart: React.FC<HCChartProps> = ({ theme, isLoading, error, tableData, chartID }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 500);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // "#54A7A8", "#3F7D7E", "#2A5354"
  const selectChart = (chartID: number) => {
    if(chartID === 457){
      return <RevenueChart data={tableData} />
    } else if(chartID === 277){
      return <RevenueChartDual data={tableData} />
    } else if (chartID === 480) {
      return <PromoChart data={tableData} />
    } else if(chartID === 216){
      return <DiscountDepthChart data={tableData} />
    } else if(chartID === 147){
      return <ProductPriceChart data={tableData} />
    } else if(chartID === 185){
      return <SizePriceChart data={tableData} />
    } else if (chartID === 403) {
      return <BrandPriceChart data={tableData} />
    } else if (chartID === 469) {
      return <RevenueGrowth data={tableData} />
    } else if (chartID === 1101) {
      return <PromoMetricsChart data={tableData} />
    } else if (chartID === 1113) {
      return <GrowthChart data={tableData} />
    } else if (chartID === 975) {
      return <RevenueShareChart data={tableData} />
    } else if (chartID === 983) {
      return <RevenueGrowthChart data={tableData} />
    } else if (chartID === 1105) {
      return <PromoVolumeChart data={tableData} />
    } else if (chartID === 988) {
      return <WaterfallChart data={tableData[0]} />
    } else if (chartID === 1055) {
      return <ProductMetricsChart data={tableData} />
    } else if (chartID === 1196) {
      return <SupplierInvoiceChart data={tableData} />
    } else if (chartID === 1198) {
      return <TerminalTypeChart data={tableData} />
    } else if (chartID === 1211) {
      return <ProductPriceInfo data={tableData} />
    } else if (chartID === 1317) {
      return <BrandCAGRChart data={tableData} />
    } else if (chartID === 1309) {
      return <GrowthContributionChart data={tableData} />
    } else if (chartID === 1312) {
      return <GrowthComponentsChart data={tableData} />
    } else if (chartID === 1363) {
      return <SKUPerformanceDashboard data={tableData} />
    } else {
      return isMobileView ? (
        <GenericChartMobile theme={theme} data={tableData} />
      ) : (
        <GenericChart theme={theme} data={tableData} />
      );
    }
  }

  return (
    <>
      {selectChart(chartID)}
    </>
  );
};

export default HCChart;
