import React, { FC, ReactElement, useEffect, useState } from "react";
import ChatInput from "../components/chatInput";
import config from "../config";
import axios from "axios";
import ChatItemDetails from "../components/chatItemDetails";
import { FaUser } from "react-icons/fa";
import logoB from "../assets/logo.png";
import { mergeStyles } from "@fluentui/react";
import { ChatInputData } from "./chatPage";

// Common style for both the icon and the image to ensure they are of the same size
export const commonIconImageStyle = mergeStyles({
  height: 40, // Adjust based on your preference
  width: 40, // Adjust to maintain aspect ratio or match height
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 10, // Adjust the spacing to the right of the icon/image
});

const DevChatPage: FC<{ username: string }> = (props): ReactElement => {
  const { username } = props;

  const [chatHistory, setChatHistory] = useState<ChatInputData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ipAddress, setIpAddress] = useState<string>("");

  const extractHistory = (history: ChatInputData[]) => {
    const historyLength = history.length;
    if (historyLength === 0) {
      return "";
    }
    return history[historyLength - 1].response.history;
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get(`${config.api.baseUrl}/sql/colab`);
        if (response.data && response.data.rows.length > 0) {
          setIpAddress(response.data.rows[0].ip_address);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIsLoading(false);
      }
    };

    fetchIpAddress();
  }, []);

  const submittedFromChatInput = async (message: string) => {
    if (ipAddress === "") {
      alert("No IP address found. Please try again later.");
      return;
    }

    try {
      setIsLoading(true);
      const axiosResponse = await axios.post(`${config.api.baseUrl}/chat`, {
        message,
        gradio_url: `${ipAddress}/question`,
        history: extractHistory(chatHistory),
        username,
      });

      const { answer, sql, history, definitions } = axiosResponse.data;
      const savedChat = { answer, sql, history, sql_id: 0, definitions };

      setChatHistory((prevHistory) => [...prevHistory, { message, response: savedChat, feedback: { display_name: "", notes: "", thumbs: null, feedback: "" } }]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error while calling the chat route:", error);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingTop: "24px", paddingRight: "24px" }}>
      {isLoading && (
        <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0, 0, 0, 0.8)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1000 }}>
          <p style={{ color: "white" }}>Loading...</p>
        </div>
      )}

      <div style={{ paddingBottom: '10px' }}>
        {chatHistory.map((chatItem, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px', gap: '10px', backgroundColor: '#2A5354', }}>
              <div className={commonIconImageStyle}><FaUser size={24} /></div> {chatItem.message}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px', gap: '10px' }}>
              <div className={commonIconImageStyle}><img src={logoB} alt="Logo" style={{ height: '100%', width: 'auto' }} /></div> <div style={{ whiteSpace: 'pre-wrap' }}>{chatItem.response.answer}</div>
            </div>
            <ChatItemDetails username={username} chatId="1" theme="dark" chatItem={chatItem} />
          </div>
        ))}
      </div>
      <ChatInput theme="dark" onMessageSubmit={submittedFromChatInput} isDisabled={isLoading} />
    </div>
  );
};

export default DevChatPage;
