import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

interface SizeData {
  size: number;  // This could also be a string if size is denoted differently
  avg_price_per_ounce: number;
  avg_promo_price_per_ounce: number;
  avg_non_promo_price_per_ounce: number;
}

interface SizePriceChartProps {
  data: SizeData[];
}

const formatGrowth = (tickItem: number) => {
  return `${(tickItem * 100).toFixed(0)}%`;
};

const formatLable = (tickItem: number) => {
  return `${tickItem} oz`;
}

const SizePriceChart: React.FC<SizePriceChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
         
        <XAxis dataKey="size" tick={{ fill: 'white' }} tickFormatter={formatLable} />
        <YAxis tick={{ fill: 'white' }} tickFormatter={formatGrowth} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Legend />
        <Bar dataKey="avg_non_promo_price_per_ounce" fill="#2A5354" name="Avg Non-Promo Price/Oz" />
        <Bar dataKey="avg_promo_price_per_ounce" fill="#3F7D7E" name="Avg Promo Price/Oz" />
        <Bar dataKey="avg_price_per_ounce" fill="#54A7A8" name="Avg Price/Oz" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SizePriceChart;
