// components/RevenueChart.js
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface RevenueData {
  channel: string;
  total_revenue_2021: number;
  total_revenue_2022: number;
  percentage_growth: number;
}

// Define the props for the component using the interface
interface RevenueChartProps {
  data: RevenueData[];
}

const formatRevenue = (tickItem: number) => {
  return `$${(tickItem / 1e6).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
};

const RevenueChart: React.FC<RevenueChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
         
        <XAxis type="number" tick={{ fill: 'white' }} tickFormatter={formatRevenue} />
        <YAxis type="category" dataKey="channel" tick={{ fill: 'white' }} />
        <Tooltip
          contentStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            borderColor: "#000",
            color: "#fff",
          }}
          cursor={false}
        />
        <Legend />
        <Bar
          dataKey="total_revenue_2021"
          fill="#2A5354"
          name="Total Revenue 2021"
        />
        <Bar
          dataKey="total_revenue_2022"
          fill="#3F7D7E"
          name="Total Revenue 2022"
        />
        <Bar
          dataKey="percentage_growth"
          fill="#54A7A8"
          name="Percentage Growth"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RevenueChart;
