import axios from "axios";
import React, { FC, useEffect, useState, CSSProperties, useRef } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import logo_text_dark from "../assets/logoB.png";
import logo_text_light from "../assets/logo.png";
import logo_l from "../assets/image_light.png";
import logo_d from "../assets/image_dark.png";

import {
  FiPlus,
  FiTrash2,
  FiMoreHorizontal,
  FiUser,
  FiLogOut,
  FiSun,
  FiMoon,
  FiChevronLeft,
  FiChevronRight,
  FiEdit3,
} from "react-icons/fi";
import { mergeStyles, Spinner, SpinnerSize } from "@fluentui/react";

const colors = {
  dark: {
    background: "#191919",
    borderColor: "#343434",
    buttonHover: "#3B9161",
    text: "#ffffff",
    optionsBackground: "#2D2D2D",
    optionsHover: "#3B9161",
  },
  light: {
    background: "#f7fafc",
    borderColor: "#e2e8f0",
    buttonHover: "#e2e8f0",
    text: "#000000",
    optionsBackground: "#ffffff",
    optionsHover: "#e2e8f0",
  },
};

interface SidebarProps {
  username: string;
  colorMode: string;
  setColorMode: (mode: "light" | "dark") => void;
  isCollapsed: boolean;
  setIsCollapsed: (isOpen: boolean) => void;
}

const Sidebar: FC<SidebarProps> = ({ username, colorMode, setColorMode, isCollapsed, setIsCollapsed }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chatList, setChatList] = useState<
    {
      id: string;
      question: string;
      display_name: string;
      notes: string;
      thumbs: boolean | null;
      feedback: string;
    }[]
  >([]);
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const [devMode, setDevMode] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [activeOptions, setActiveOptions] = useState<string | null>(null);
  // const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [optionsPosition, setOptionsPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const [renamingChatId, setRenamingChatId] = useState<string | null>(null);
  const [newDisplayName, setNewDisplayName] = useState<string>("");

  const handleDelete = async (chatId: string) => {
    console.log("Delete clicked for chat:", chatId);
    setActiveOptions(null);
    setIsLoading(true);
    try {
      await axios.post(`${config.api.baseUrl}/chat/question_feedback`, {
        question_id: chatId,
        username: username,
        is_public: "FALSE",
      });

      setChatList([]);
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.error("Error deleting chat:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRename = (chatId: string) => {
    console.log("Rename clicked for chat:", chatId);
    setRenamingChatId(chatId);
    const chat = chatList.find((chat) => chat.id === chatId);
    setNewDisplayName(chat ? chat.display_name || chat.question : ""); // Set initial value of the input field
    setActiveOptions(null);
  };

  const submitRename = async (chatId: string) => {
    setIsLoading(true);
    try {
      await axios.post(`${config.api.baseUrl}/chat/question_feedback`, {
        question_id: chatId,
        username: username,
        display_name: newDisplayName,
      });
      setRenamingChatId(null);
      setChatList([]);
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.error("Error renaming chat:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRenameSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (renamingChatId) {
      submitRename(renamingChatId);
    }
  };

  const handleRenameCancel = () => {
    setRenamingChatId(null); // Cancel renaming
  };

  useEffect(() => {
    const fetchChatList = async () => {
      try {
        const response = await axios.post(
          `${config.api.baseUrl}/chat/chat_list`,
          { username }
        );
        if (response.data) {
          setChatList(
            response.data.rows.filter(
              (row: { ids: string; is_public: boolean | null }) =>
                row.is_public !== false
            )
          );

          // setChatList(response.data.rows);
          setDevMode(response.data?.dev_flag === "true");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching chat list:", error);
        setIsLoading(false);
      }
    };

    fetchChatList();
  }, [username, refresh]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setActiveOptions(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onChatCreate = (id: string) => {
    navigate(`/chat/${id}`);
    setRefresh((prev) => prev + 1);
  };

  const onDevChatCreate = () => {
    navigate(`/dev_chat/`);
  };

  const toggleColorMode = () => {
    if (colorMode === "dark") {
      setColorMode("light");
    } else {
      setColorMode("dark");
    }
  };

  const currentColors = colorMode === "dark" ? colors.dark : colors.light;

  const toggleButtonStyle: CSSProperties = {
    position: "absolute",
    right: "-1px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: currentColors.background,
    border: `1px solid ${currentColors.borderColor}`,
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    zIndex: 2,
    color: currentColors.text,
  };

  const sidebarStyle: CSSProperties = {
    width: isCollapsed ? "2rem" : "16rem",
    height: "100vh",
    padding: "0.5rem",
    color: currentColors.text,
    backgroundColor: currentColors.background,
    zIndex: 1,
    transition: "all ease 0.5s",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  };

  const logoContainerStyle: CSSProperties = {
    height: isCollapsed ? 37 : 37 * 1.2,
    paddingTop: 30,
    paddingBottom: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const buttonStyle: CSSProperties = {
    borderWidth: "1px",
    borderColor: currentColors.borderColor,
    borderRadius: "4px",
    padding: "0.5rem",
    justifyContent: "flex-start",
    transition: "all ease 0.5s",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: currentColors.text,
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const mainActionsStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    flexGrow: 1,
  };

  const buttonHoverStyle: CSSProperties = {
    backgroundColor: currentColors.buttonHover,
  };

  const chatHistoryWrapperStyle: CSSProperties = {
    height: "calc(100vh - 360px)",
    overflowY: "auto",
    position: "relative",
  };

  const chatHistoryStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  };

  const optionsMenuStyle: CSSProperties = {
    position: "absolute",
    backgroundColor: currentColors.optionsBackground,
    padding: "0.5rem",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    zIndex: 1000,
    minWidth: "150px",
  };

  const optionsButtonStyle: CSSProperties = {
    ...buttonStyle,
    width: "100%",
    padding: "0.5rem 1rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    border: "none",
    backgroundColor: "transparent",
    transition: "background-color 0.2s ease",
  };

  const additionalOptionsStyle: CSSProperties = {
    marginTop: "auto",
    paddingBottom: "1rem",
  };

  const handleOptionsClick = (e: React.MouseEvent, chatId: string) => {
    e.stopPropagation();
    setActiveOptions(activeOptions === chatId ? null : chatId);
    const rect = e.currentTarget.getBoundingClientRect();
    setOptionsPosition({
      top: rect.top + window.scrollY + 15, // Keep the top as where it was clicked
      left: rect.right + window.scrollX - 168, // Adjust to position right of the clicked element with some spacing
    });
  };

  return (
    <div style={sidebarStyle}>
      <div
        style={toggleButtonStyle}
        onClick={() => setIsCollapsed(!isCollapsed)}
        title={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      >
        {isCollapsed ? (
          <FiChevronRight size={16} />
        ) : (
          <FiChevronLeft size={16} />
        )}
      </div>

      <div style={logoContainerStyle}>
        {isCollapsed ? (
          <img
            src={colorMode !== "dark" ? logo_d : logo_l}
            alt="Logo"
            style={{ height: "100%", width: "auto" }}
          />
        ) : (
          <img
            src={colorMode === "dark" ? logo_text_light : logo_text_dark}
            alt="Logo"
            className={mergeStyles({
              height: 37 * 1.5,
              width: "auto",
            })}
          />
        )}
      </div>

      <div style={mainActionsStyle}>
        {/* New Chat Button - Always visible */}
        <div
          style={{
            ...buttonStyle,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          onClick={() => onChatCreate("0")}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor =
              buttonHoverStyle.backgroundColor || "";
          }}
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
          }
          title={isCollapsed ? "New Chat" : undefined}
        >
          <FiPlus size={16} />
          {!isCollapsed && <span>New Chat</span>}
        </div>

        {/* Chat History - Only visible when expanded */}
        {!isCollapsed && (
          <>
            <div style={chatHistoryWrapperStyle}>
              {isLoading ? (
                <Spinner
                  size={SpinnerSize.small}
                  label="Loading..."
                  styles={{
                    circle: { borderTopColor: "white" },
                    label: { color: "white" },
                  }}
                />
              ) : (
                <div style={chatHistoryStyle}>
                  {chatList.map((chat) => (
                    <div
                      key={chat.id}
                      style={{
                        ...buttonStyle,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={() => onChatCreate(chat.id)}
                    >
                      {renamingChatId === chat.id ? (
                        <form onSubmit={handleRenameSubmit}>
                          <input
                            type="text"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                            onBlur={handleRenameCancel} // Cancel if focus is lost
                            style={{
                              width: "100%",
                              flexGrow: 1,
                              backgroundColor: currentColors.background,
                              color: currentColors.text,
                              border: `1px solid ${currentColors.borderColor}`,
                              borderRadius: "4px",
                              padding: "0.5rem",
                            }}
                            autoFocus
                          />
                        </form>
                      ) : (
                        <span
                          style={{
                            flexGrow: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {chat.display_name && chat.display_name.length > 0
                            ? chat.display_name
                            : chat.question}
                        </span>
                      )}
                      {renamingChatId !== chat.id && (
                        <div
                          onClick={(e) => handleOptionsClick(e, chat.id)}
                          style={{ cursor: "pointer", flexShrink: 0 }}
                        >
                          <FiMoreHorizontal />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <hr
              style={{
                margin: "1rem 0",
                borderColor: currentColors.borderColor,
              }}
            />
          </>
        )}

        {/* Dev Chat Button - Always visible */}
        {devMode && (
          <div
            style={{
              ...buttonStyle,
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            onClick={onDevChatCreate}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                buttonHoverStyle.backgroundColor || "";
            }}
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = "transparent")
            }
            title={isCollapsed ? "Dev Chat" : undefined}
          >
            <FiPlus size={16} />
            {!isCollapsed && <span>Dev Chat</span>}
          </div>
        )}
      </div>

      {/* Options Menu */}
      {activeOptions && optionsPosition && (
        <div
          ref={optionsRef}
          style={{
            ...optionsMenuStyle,
            top: optionsPosition.top,
            left: optionsPosition.left,
          }}
        >
          <button
            style={optionsButtonStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleRename(activeOptions); // Trigger rename action
            }}
          >
            <FiEdit3 />
            <span>Rename</span>
          </button>
          <button
            style={optionsButtonStyle}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(activeOptions); // Trigger delete action
            }}
          >
            <FiTrash2 />
            <span>Delete</span>
          </button>
        </div>
      )}

      {/* Bottom Options Section */}
      <div style={additionalOptionsStyle}>
        <div
          style={{
            ...buttonStyle,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          onClick={toggleColorMode}
          title={
            isCollapsed
              ? colorMode === "dark"
                ? "Light mode"
                : "Dark mode"
              : undefined
          }
        >
          {colorMode === "dark" ? <FiSun /> : <FiMoon />}
          {!isCollapsed && (
            <span>{colorMode === "dark" ? "Light mode" : "Dark mode"}</span>
          )}
        </div>

        {/* <div
          style={{
            ...buttonStyle,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          title={isCollapsed ? "Updates & FAQ" : undefined}
        >
          <FiExternalLink />
          {!isCollapsed && <span>Updates & FAQ</span>}
        </div> */}

        <div
          style={{
            ...buttonStyle,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          title={isCollapsed ? username : undefined}
        >
          <FiUser />
          {!isCollapsed && <span style={{ flexGrow: 1 }}>{username}</span>}
        </div>

        <div
          style={{
            ...buttonStyle,
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          title={isCollapsed ? "Log Out" : undefined}
        >
          <FiLogOut />
          {!isCollapsed && <span>Log Out</span>}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
