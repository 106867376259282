import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from "recharts";

type GrowthData = {
    year: number;
    price_driven_growth: number;
    volume_driven_growth: number;
    mix_driven_growth: number;
    total_growth: number;
};

type GrowthComponentsChartProps = {
    data: GrowthData[];
};

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: "black",
                color: "white",
                padding: "8px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
            }}>
                <p style={{ fontWeight: "bold" }}>{`Year: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const GrowthComponentsChart: React.FC<GrowthComponentsChartProps> = ({ data }) => {
    const formatValue = (value: number) => 
        value.toLocaleString(undefined, { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        });
    const formaTick = (value: number) => "$" + (value / 1000000) + "M";
    
    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <XAxis dataKey="year" />
                <YAxis tickFormatter={formaTick} />
                <Tooltip cursor={false} content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="price_driven_growth" fill="#54A7A8" name="Price Driven">
                    <LabelList dataKey="price_driven_growth" position="top" formatter={formatValue} />
                </Bar>
                <Bar dataKey="volume_driven_growth" fill="#3F7D7E" name="Volume Driven">
                    <LabelList dataKey="volume_driven_growth" position="top" formatter={formatValue} />
                </Bar>
                <Bar dataKey="mix_driven_growth" fill="#2A5354" name="Mix Driven">
                    <LabelList dataKey="mix_driven_growth" position="top" formatter={formatValue} />
                </Bar>
                <Bar dataKey="total_growth" fill="#E7E6E6" name="Total Growth">
                    <LabelList dataKey="total_growth" position="top" formatter={formatValue} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default GrowthComponentsChart;