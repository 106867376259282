import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Cell,
} from "recharts";

type GrowthData = {
    channel: string;
    manufacturer: string;
    brand: string;
    growth_contribution_percentage: number;
};

type GrowthContributionChartProps = {
    data: GrowthData[];
};

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                }}
            >
                <p style={{ margin: 0 }}>
                    <strong>{payload[0].payload.channel}</strong>
                </p>
                <p style={{ margin: 0 }}>
                    Growth Contribution: {payload[0].value.toFixed(2)}%
                </p>
            </div>
        );
    }

    return null;
};

const GrowthContributionChart: React.FC<GrowthContributionChartProps> = ({ data }) => {
    const colors = ["#54A7A8", "#3F7D7E", "#2A5354"];

    const sortedData = [...data]
        .sort((a, b) => b.growth_contribution_percentage - a.growth_contribution_percentage)
        .map((item, index) => ({
            ...item,
            color: colors[index % colors.length],
        }));

    const formatGrowth = (value: number) => `${value.toFixed(2)}%`;

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={sortedData}
                margin={{
                    top: 20,
                    right: 100,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="channel" />
                <YAxis
                    tickFormatter={formatGrowth}
                    label={{ value: 'Growth Contribution %', angle: -90, position: 'insideLeft' }}
                />
                <Tooltip cursor={false} content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="growth_contribution_percentage" name="Growth Contribution">
                    {sortedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                    <LabelList dataKey="growth_contribution_percentage" position="top" formatter={formatGrowth} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default GrowthContributionChart;