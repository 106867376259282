import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type PromoVolumeChartProps = {
  data: object[];
};

const PromoVolumeChart = ({ data }: PromoVolumeChartProps) => {
  // Define consistent colors
  const color1 = "#54A7A8"; // Primary color for Incremental Volume
  const color2 = "#3F7D7E"; // Secondary color for Baseline Volume
  const color3 = "#2A5354"; // Tertiary color for Volume Uplift (%)

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
         
        <XAxis dataKey="promo_type" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="total_incremental_volume"
          fill={color1}
          name="Incremental Volume"
        />
        <Bar
          dataKey="total_baseline_volume"
          fill={color2}
          name="Baseline Volume"
        />
        <Bar
          dataKey="volume_uplift_percentage"
          fill={color3}
          name="Volume Uplift (%)"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PromoVolumeChart;
