import React from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
} from 'recharts';

interface RevenueData {
  recent_year: number;
  previous_year: number;
  recent_revenue: number;
  previous_revenue: number;
  revenue_growth_percentage: number;
}

interface RevenueChartDualProps {
  data: RevenueData[];
}

const formatRevenue = (tickItem: number) => {
  return `$${(tickItem / 1e6).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}M`;
};

const formatGrowth = (tickItem: number) => {
  return `${(tickItem).toFixed(0)}%`;
};

const RevenueChartDual: React.FC<RevenueChartDualProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
         
        <XAxis dataKey="recent_year" stroke="#fff" />
        <YAxis yAxisId="left" orientation="left" stroke="#fff" tickFormatter={formatRevenue} />
        <YAxis yAxisId="right" orientation="right" stroke="#fff" tickFormatter={formatGrowth} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', borderColor: '#000', color: '#fff' }}
          cursor={false}
        />
        <Legend />
        <Bar yAxisId="left" dataKey="recent_revenue" fill="#2A5354" name="Recent Revenue" />
        <Bar yAxisId="left" dataKey="previous_revenue" fill="#3F7D7E" name="Previous Revenue" />
        <Line yAxisId="right" type="monotone" dataKey="revenue_growth_percentage" stroke="#54A7A8" name="Revenue Growth (%)" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default RevenueChartDual;
