import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from 'recharts';

interface GrowthData {
  revenue_growth: number;
  price_driven_growth: number;
  volume_driven_growth: number;
  mix_driven_growth: number;
}

interface WaterfallChartProps {
  data: GrowthData;
}

const formatValue = (value: number) => {
  return `$${(value / 1000000).toFixed(2)}M`;
};

const WaterfallChart: React.FC<WaterfallChartProps> = ({ data }) => {
  const chartData = [
    { name: 'Price Driven', value: data.price_driven_growth, color: '#54A7A8' },
    { name: 'Volume Driven', value: data.volume_driven_growth, color: '#3F7D7E' },
    { name: 'Mix Driven', value: data.mix_driven_growth, color: '#2A5354' },
    { name: 'Total Growth', value: data.revenue_growth, color: '#E7E6E6' },
  ];

  let cumulative = 0;
  const processedData = chartData.map((item, index) => {
    if (index === chartData.length - 1) {
      return { ...item, start: 0, end: item.value };
    }
    const start = cumulative;
    cumulative += item.value;
    return { ...item, start, end: cumulative };
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={processedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
         
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatValue} />
        <Tooltip
          formatter={(value: number) => formatValue(value)}
          labelFormatter={(label: string) => `${label} Growth`}
        />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="start" stackId="a" fill="none" />
        <Bar dataKey="end" stackId="a" fill="#8884d8">
          {processedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          {processedData.map((entry, index) => (
            <text
              key={`label-${index}`}
              x={index * 150 + 75}
              y={entry.value >= 0 ? -10 : 10}
              fill={entry.color}
              textAnchor="middle"
              dy={entry.value >= 0 ? -10 : 20}
            >
              {formatValue(entry.value)}
            </text>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WaterfallChart;