import React from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis,  Tooltip, Legend, ResponsiveContainer } from 'recharts';

type MonthlyData = {
  month: number;
  distinct_products: number;
  total_invoice_value: number;
  min_price_per_unit: number;
  max_price_per_unit: number;
  avg_price_per_unit: number;
  number_of_vendors: number;
};

type MonthlyProductAnalysisChartProps = {
  data: MonthlyData[];
};

const MonthlyProductAnalysisChart: React.FC<MonthlyProductAnalysisChartProps> = ({ data }) => {
  const processedData = data.map(item => ({
    ...item,
    distinct_products: Number(item.distinct_products),
    total_invoice_value: Number(item.total_invoice_value),
    avg_price_per_unit: Number(item.avg_price_per_unit),
    number_of_vendors: Number(item.number_of_vendors),
    month: `Month ${item.month}`
  }));

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatTooltip = (value: number, name: string) => {
    switch (name) {
      case 'total_invoice_value':
      case 'min_price_per_unit':
      case 'max_price_per_unit':
      case 'avg_price_per_unit':
        return formatCurrency(value);
      default:
        return value.toLocaleString();
    }
  };

  return (
    <ResponsiveContainer width="100%" height={600}>
      <ComposedChart
        data={processedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
         
        <XAxis dataKey="month" />
        <YAxis yAxisId="left" label={{ value: 'Total Invoice Value', angle: -90, position: 'insideLeft' }} />
        <YAxis yAxisId="right" orientation="right" label={{ value: 'Price per Unit', angle: 90, position: 'insideRight' }} />
        <Tooltip formatter={formatTooltip} />
        <Legend />
        <Bar dataKey="total_invoice_value" yAxisId="left" fill="#54A7A8" name="Total Invoice Value" />
        <Line type="monotone" dataKey="avg_price_per_unit" yAxisId="right" stroke="#3F7D7E" name="Avg Price per Unit" />
        <Line type="monotone" dataKey="min_price_per_unit" yAxisId="right" stroke="#2A5354" name="Min Price per Unit" />
        <Line type="monotone" dataKey="max_price_per_unit" yAxisId="right" stroke="#E7E6E6" name="Max Price per Unit" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default MonthlyProductAnalysisChart;