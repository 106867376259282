import { getTheme, IStackItemTokens, IStackStyles, IStackTokens } from '@fluentui/react'
const theme = getTheme();

export const rootStackStyles: IStackStyles = {
    root: {
        height: 'calc(100vh - 48px)'
    }
}

export const headerStackStyles: IStackStyles = {
    root: {
        height: 48,
        // background: theme.palette.themeDarker
        background: '#2A5354'
    }
}

export const listItemsStackStyles: IStackStyles = {
    root: {
        padding: '10px'
    }
}

export const mainStackStyles = (colorMode: "light" | "dark"): IStackStyles => ({
    root: {
        background: colorMode === "dark" ? "black" : "white",
    }
})

export const sidebarStackStyles = (colorMode: "light" | "dark"): IStackStyles => ({
    root: {
      background: colorMode === "dark" ? "black" : "white",
      boxShadow: theme.effects.elevation8,
      color: colorMode === "dark" ? "white" : "black",
    },
  });

export const titleStackStyles: IStackStyles = {
    root: {
        alignItems: 'center',
        background: '#fafafa',
    }
}

export const stackPadding: IStackTokens = {
    padding: 10
}

export const stackGaps: IStackTokens = {
    childrenGap: 10
}

export const stackItemPadding: IStackItemTokens = {
    padding: 10
}

export const stackItemMargin: IStackItemTokens = {
    margin: 10
}