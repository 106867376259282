
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: 'calc(100vh - 20px)',
  },
});


const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const domain = "chatrgmai.us.auth0.com";
  const clientId = "lRA3iIgMHeOcT8VigARAKzh8wyYq0IVA";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
      {children}
    </Auth0Provider>
  );
};

ReactDOM.render(<Auth0ProviderWithHistory>
  <App />
</Auth0ProviderWithHistory>, document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
